.text-head-main {
  display: flex;
  justify-content: center;
  margin: 5rem 0 3rem 0;

  @media (max-width: 600px) {
    margin: 3rem 0 1rem 0;
  }

  .text-head {
    font-family: 'Bai Jamjuree', sans-serif;
    width: max-content;
    color: #272727;
    font-weight: 700;
    font-size: 40px;
    text-transform: capitalize;
    text-align: center;
    position: relative;

    @media (max-width: 600px) {
      font-size: 30px;
    }

    &::after {
      z-index: -1;
      content: "";
      width: 80%;
      height: 10px;
      border-radius: 99rem;
      position: absolute;
      bottom: 7px;
      left: -15px;
      background: rgba(0, 149, 218, 0.25);

      @media (max-width: 600px) {
        bottom: 3px;
        left: -8px;
      }
    }
  }
}

.workshop-img {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: -0.75rem;
    bottom: -0.75rem;
    background-color: #1c95da;
    z-index: -1;
    opacity: 0.4;
    transition: 0.5s;
  }

  &:hover {
    

    &::after {
      opacity: 1;
      right: -1rem;
    bottom: -1rem;
    }
  }
}
