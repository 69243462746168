@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Signika:wght@300&display=swap");

@import "./_index";
@import "./_fonts";
@import "./_common";
@import "./_header";
@import "./slider";
@import "./home";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fcfcfc;
  line-height: 1.5;
  font-family: "Bai Jamjuree", sans-serif;
}

iframe {
  position: relative !important;
  height: 0 !important;
  width: 0 !important;
  display: none;
}
