.Header {
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: #1c95da;
  //   border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 3px, rgba(0, 0, 0, 0.35) 0px 1px 2px;
  z-index: 999;
  transition: 0.5s;

  .navbar {
    .nav-link {
      color: #fff;
      text-decoration: none;
      font-weight: 500;
      font-size: 16px;
      margin-right: 2rem;
      text-transform: uppercase;
    }
  }
}

.nav-link-mobile {
  padding: 1rem;
  color: #fff;
}

.Header-bg {
  background-color: transparent;
  border-bottom: none;
  box-shadow: none;

  .navbar {
    .nav-link {
      color: #ffffff;
    }
  }
}
