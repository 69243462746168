@font-face {
  font-family: "Inter";
  src: local("Inter-Regular"),
    url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Medium"),
    url("../fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Bold"), url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}



