.hero-main {
  position: relative;
  margin-top: -88px;

  .hero-slider-card {
    height: 100vh;
    width: 100%;
    background-size: cover;
    object-fit: contain;

    @media (max-width: 600px) {
      height: 80vh;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 1000%;
      background-color: rgba($color: #000000, $alpha: 0.7);
    }
  }

  .slider-img-1 {
    background-image: url(../images/Slider-img/slider-1.jpg);
    background-position: center;
  }
  .slider-img-2 {
    background-image: url(../images/Slider-img/slider-2.jpg);
    background-position: center;
  }
  .slider-img-3 {
    background-image: url(../images/Slider-img/slider-3.jpg);
    background-position: center;
  }
  .slider-img-4 {
    background-image: url(../images/Slider-img/slider-4.jpg);
    background-position: center;
  }
}

.img-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
}

.hero-title {
  font-weight: 700;
  font-size: 52px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  text-transform: capitalize;
  text-align: center;
  z-index: 99;
  width: 40%;

  @media (max-width: 600px) {
    top: 50%;
    font-size: 32px;
    width: 70%;
  }
}

// services section css

.services-link {
  text-decoration: none;
}

.services-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  border-radius: 12px;
  transition: 0.5s ease-in-out;
  padding: 1rem 0;
  border: 1px solid rgba(0, 149, 218, 0.4);

  .services-img {
    height: 200px;
    display: flex;
    justify-content: center;

    & > img {
      width: 90%;
      aspect-ratio: 2/2;
      object-fit: contain;

      @media (max-width: 600px) {
        width: 70%;
      }
    }
  }

  .services-name {
    color: #272727;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 25px;
    margin-top: 2rem;

    @media (max-width: 600px) {
      font-size: 20px;
      margin-top: 1rem;
    }
  }

  &:hover {
    // box-shadow: rgba(50, 50, 93, 0.25) 0px -7px 15px -10px inset,rgba(0, 0, 0, 0.3) 0px 28px 50px -18px inset;
    box-shadow: rgba(0, 149, 218, 0.2) 0px 8px 24px;
    border: 1px solid rgba(0, 149, 218, 0.4);

    .services-name {
      color: #0095da;
    }
  }
}

// who-we-are

.who-we-imgbox {
  height: 234px;
  width: 100%;
  box-shadow: 5px 10px rgba($color: #0095da, $alpha: 0.25);
  transition: 0.5s;

  @media (max-width: 600px) {
    height: 150px;
    background-position: center;
  }

  &:hover {
    box-shadow: 10px 15px rgba($color: #0095da, $alpha: 0.5);
  }
}

.who-imgbox-1 {
  background-image: url(../images/who-we-img1.png);
}

.who-imgbox-2 {
  background-image: url(../images/who-we-img2.png);
}

// Project-home
.project-grid {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: 600px) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }

  .project-img {
    width: 100%;
    min-height: 200px;
    // background: url(https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: 600px) {
      min-height: 150px;
    }
  }

  .project-item-1 {
    grid-row: 1/3;
    grid-column: 1/3;
    background-image: url("../images/Home Page Product/3.JPG");
    background-position: top;

    @media (max-width: 600px) {
      grid-row: auto;
      grid-column: auto;
    }
  }

  .project-item-2 {
    background-image: url("../images/Home Page Product/2.jpg");
  }

  .project-item-3 {
    background-image: url("../images/Home Page Product/1.jpeg");
    background-position: top
  }

  .project-item-4 {
    background-image: url("../images/Home Page Product/4.jpeg");
    // background-position: top;
  }

  .project-item-5 {
    background-image: url("../images/Home Page Product/5.JPG");
  }

  .project-item-6 {
    grid-row: 3/5;
    grid-column: 2/4;
    background-image: url("../images/Home Page Product/6.jpg");

    @media (max-width: 600px) {
      grid-row: auto;
      grid-column: auto;
    }
  }
}
